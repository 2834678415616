<template>
  <div class="edit-connection">
    <CForm
      ref="updateTag"
      v-on:submit.prevent="updateConnection"
      novalidate
      class="update-tag-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editSQS.title"
        submit-title="update"
      />
      <FormBuilder
        :list="formList"
        :isCreateHeader="false"
        @updated="updateFormData"
        :page="{
          title: texts.editSQS.title,
          info: descriptions,
          mode: 'update',
        }"
      />
    </CForm>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from '@/config';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import CreateHeader from '@/shared/components/CreateHeader';
import { connections } from '@/config/descriptions/connections';
import { connectionsTexts } from '@/config/pageTexts/connections.json';
import sqsConnectionFields from '@/generated/ziqni/store/modules/sqsConnections/fields';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';

export default {
  name: 'EditConnectionSQS',
  components: {
    CreateHeader,
  },
  data() {
    return {
      model: 'SqsConnection',
      descriptions: {
        ...connections.edit.sqs,
      },
      texts: {
        ...connectionsTexts,
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      customFields: [],
      formList: [],
      formData: {
        id: '',
        created: '',
        objectType: 'SqsConnection',
        name: '',
        description: '',
        uri: '',
        acessKey: '',
        secretKey: '',
        transformerId: '',
        customFields: {},
        metadata: [],
        tags: [],
      },
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      transformerOptions: [],
      nameValidate: null,
      transValidate: null,
      transId: '',
      uriValidate: null,
      accessValidate: null,
      secretValidate: null,
      options: ['tag1', 'tag2', 'tag3'],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('connections', ['success', 'message', 'loading', 'connections', 'connection']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className =
        this.theme === 'main'
          ? 'error-validation--multiselect'
          : 'error-validation';

      return {
        [className]: this.transValidate === false,
      };
    },
    created() {
      return dateFormate(this.formData.created);
    },
  },
  watch: {
    connection() {
      this.formData = cloneDeep(this.connection);
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val.transformerId) this.transValidate = true;
        if (val.name) this.nameValidate = true;
        if (val.uri) this.uriValidate = true;
        if (val.acessKey) this.accessValidate = true;
        if (val.secretKey) this.secretValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('connections', ['handleUpdateSQSConnections', 'handleGetSQSConnectionsById']),
    ...mapActions('customFields', ['handleGetCustomFields']),
    getCustomFields() {
      this.handleGetCustomFields({
        queryData: {},
      }).then((data) => {
        this.customFields = data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    initialize() {
      this.handleGetSQSConnectionsById(this.$route.params.id)
          .then(data => {
            routerBreadcrumbs(this.$router.currentRoute, {
              name: data[0].name,
            });
          });

      this.formList = fieldHelpers.prepareEditFormList(
          sqsConnectionFields,
          this.texts.editPage,
          this.descriptions,
          this.connection
      );
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
    dateFormate,
    updateConnection() {
      this.transValidate = !!this.formData.transformerId;
      this.nameValidate = !!this.formData.name;
      this.uriValidate = !!this.formData.uri;
      this.accessValidate = !!this.formData.acessKey;
      this.secretValidate = !!this.formData.secretKey;

      delete this.formData.lastKnownStatus
      delete this.formData.lastKnownStatusCode
      delete this.formData.connectionType
      delete this.formData.spaceName
      delete this.formData.created

      const body = this.formData;

      this.handleUpdateSQSConnections(body);
    },
  },
};
</script>

<style lang="scss">
.edit-connection {
  height: 100%;
  .edit-connection-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select.zq--multiselect.zq--multiselect__select {
        .multiselect__select {
          opacity: 1;
        }
      }
    }
  }
}
</style>
